<template>
  <div class="row" v-if="!ready">
    Loading...
  </div>
  <div class="row bg_light p-1 pt-5 pb-5" v-else>
    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Nature of visit
      </div>
      <v-select
        label="name"
        :options="categories"
        v-model="details.categoryId"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
        :reduce="item => item.id"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3" v-if="subcategories">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Subcategory
      </div>
      <v-select
        label="name"
        :options="subcategories"
        v-model="details.subcategoryId"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
        :reduce="item => item.id"
      />
    </div>
    <div class="col-lg-6 col-12 pb-3" v-else>
      &nbsp;
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Property
      </div>
      <v-select
        label="name"
        :options="properties"
        v-model="details.propertyId"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
        :reduce="item => item.id"
         @input="selectedProperty()"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Lodge
      </div>
      <v-select
        label="name"
        :options="lodges || []"
        v-model="details.lodgeId"
        :searchable="false"
        :clearable="false"
        :disabled="saving || (lodges && lodges.length <= 1) || !lodges"
        :reduce="item => item.id"
         @input="selectedLodge()"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Room Type
      </div>
      <v-select
        label="name"
        :options="roomTypes || []"
        v-model="details.roomTypeId"
        :searchable="false"
        :clearable="false"
        :disabled="saving || (roomTypes && roomTypes.length <= 1) || !roomTypes"
        :reduce="item => item.id"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Treehouse
      </div>
      <v-select
        label="name"
        :options="treehousesOptions"
        v-model="details.treehouses"
        :searchable="false"
        :clearable="false"
        :disabled="saving || !treehouseAvailable"
        :reduce="item => item.value"
      />
    </div>

    <div class="col-lg-6 col-12 pb-4">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Date in
      </div>
      <datepicker input-class="form-control form-control-sand clickable" calendar-class="text_secondary" :disabled="saving" :disabled-dates="dateInState.disabled" @selected="changedDateIn" :value="details.dateIn"></datepicker>
    </div>

    <div class="col-lg-6 col-12 pb-4">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Date out
      </div>
      <datepicker input-class="form-control form-control-sand clickable" calendar-class="text_secondary" :disabled="saving" :disabled-dates="dateOutState.disabled" @selected="changedDateOut" :value="details.dateOut" :open-date="details.dateIn"></datepicker>
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Nights
      </div>
      <input class="form-control form-control-sand" type="number" min=0 v-model="details.noNights" disabled="disabled">
    </div>

    <div class="col-6">
      &nbsp;
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Adults
      </div>
      <input class="form-control form-control-sand" type="number" min=0 v-model.number="details.adults" :disabled="saving">
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Children
      </div>
      <input class="form-control form-control-sand" type="number" min=0 v-model.number="details.children" :disabled="saving" @change="childArraySize()">
    </div>

    <div class="offset-lg-6 col-lg-6 col-12 pb-3" v-for="i in details.children" :key=i>
      <div class="col-12 mb-1 mb-md-2 p-0">
        Age child {{i}}
      </div>
      <v-select
        :options="kidsAgesOptions"
        v-model="details.childrenAges[i-1]"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Transport
      </div>
      <v-select
        :options="transportOptions"
        v-model="details.transport"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Tours
      </div>
      <v-select
        label="name"
        :options="[{ name: 'Yes', value: true }, { name: 'No', value: false }]"
        v-model="details.tours"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
        :reduce="item => item.value"
      />
    </div>

    <div class="col-lg-6 col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        News
      </div>
      <v-select
        label="name"
        :options="[{ name: 'Yes', value: true }, { name: 'No', value: false }]"
        v-model="details.news"
        :searchable="false"
        :clearable="false"
        :disabled="saving"
        :reduce="item => item.value"
      />
    </div>

    <div class="col-12 pb-3">
      <div class="col-12 mb-1 mb-md-2 p-0">
        Anything else
      </div>
      <textarea name="comments" v-model.trim="details.comments" class="form-control form-control-sand" :disabled="saving"></textarea>
    </div>

    <div class="col-12 pt-5" v-if="success">
      Enquiry successfully submitted!
    </div>
    <div class="col-12 pt-5 text-center" v-else>
      <button class="btn button-darkest" @click="save" :disabled="saving"><span v-if="saving">Saving...</span><span v-else>Save</span></button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

const kidsAges = 15

export default {
  name: 'BookYourNextJourney',
  components: { Datepicker, vSelect },
  data () {
    return {
      dateInState: {
        disabled: {
          to: moment().toDate()
        }
      },
      details: {
        adults: null,
        categoryId: null,
        children: null,
        childrenAges: null,
        comments: null,
        dateIn: null,
        dateOut: null,
        lodgeId: null,
        news: null,
        noNights: null,
        propertyId: null,
        roomTypeId: null,
        site: 'OGP',
        subcategoryId: null,
        tours: null,
        transport: null,
        treehouses: null
      },
      kidsAgesOptions: [],
      lodges: null,
      ready: false,
      roomTypes: null,
      saving: false,
      success: null,
      transportOptions: ['Fly', 'Drive', 'Transfer'],
      treehouseAvailable: false
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'properties'
    ]),
    dateOutState () {
      return {
        disabled: {
          to: moment(this.details.dateIn).toDate()
        }
      }
    },
    subcategories () {
      let subCats
      for (const c of this.categories) {
        if (c.id === this.details.categoryId && c.subcategories) {
          subCats = c.subcategories
          break
        }
      }
      return subCats
    },
    treehousesOptions () {
      if (this.treehouseAvailable) {
        return [{ name: 'Yes', value: true }, { name: 'No', value: false }]
      }
      return []
    }
  },
  created () {
    for (let kidsAge = 0; kidsAge <= kidsAges; kidsAge++) {
      this.kidsAgesOptions.push(kidsAge.toString())
    }

    const promises = []
    if (!this.categories) {
      promises.push(this.getCategories())
    }
    if (!this.properties) {
      promises.push(this.getProperties())
    }
    Promise.all(promises).then(() => {
      this.ready = true
    })
  },
  methods: {
    ...mapActions([
      'createEnquiry',
      'getCategories',
      'getProperties'
    ]),
    changedDateIn (d) {
      d = moment(d).startOf('day')
      this.details.dateIn = d.toDate()
      if (this.details.dateOut) {
        if (moment(this.details.dateOut).isBefore(d)) {
          this.details.dateOut = d.add(1, 'd').toDate()
        }
        this.details.noNights = moment(this.details.dateOut).diff(d, 'days')
      }
    },
    changedDateOut (d) {
      d = moment(d).startOf('day')
      this.details.dateOut = d.toDate()
      this.details.noNights = moment(d).diff(this.details.dateIn, 'days')
    },
    childArraySize () {
      if (parseInt(this.details.children) === 0) {
        this.details.childrenAges = []
      } else {
        this.details.childrenAges = Array(this.details.children)
      }
    },
    resefForm () {
      this.details = {
        adults: null,
        categoryId: null,
        children: null,
        childrenAges: null,
        comments: null,
        dateIn: null,
        dateOut: null,
        lodgeId: null,
        news: null,
        noNights: null,
        propertyId: null,
        roomTypeId: null,
        site: 'OGP',
        subcategoryId: null,
        tours: null,
        transport: null,
        treehouses: null
      }
      this.selectedTE = null
      this.dateInState = {
        disabled: {
          to: moment().toDate()
        }
      }
      this.people = 10
      this.kidsAges = 15
      this.lodges = null
      this.roomTypes = null
      this.saving = false
      this.assignIt = true
      this.showModalDontAssign = false
    },
    save () {
      this.saving = true
      let dt = moment(this.details.dateIn)
      if (dt.isValid()) {
        this.details.dateStart = dt.format('YYYY-MM-DD')
      } else {
        this.details.dateStart = null
      }
      dt = moment(this.details.dateOut)
      if (dt.isValid()) {
        this.details.dateEnd = dt.format('YYYY-MM-DD')
      } else {
        this.details.dateEnd = null
      }
      this.createEnquiry(this.details).then(enqId => {
        if (enqId) {
          this.success = true
          setTimeout(() => {
            this.success = null
            this.resefForm()
            this.saving = false
          }, 3000)
        } else {
          alert('Error saving enquiry. Please try again')
          this.saving = false
        }
      }, e => {
        this.saving = false
      })
    },
    selectedLodge (lodge) {
      this.details.treehouses = null
      this.details.roomTypeId = null
      this.roomTypes = null
      this.treehouseAvailable = false
      if (this.details.lodgeId) {
        lodge = lodge || this.lodges.filter(l => l.id === this.details.lodgeId)[0]
        this.treehouseAvailable = lodge.treehouses && lodge.treehouses.length > 1
        this.roomTypes = lodge.roomTypes
        if (this.roomTypes && lodge.roomTypes.length === 1) {
          this.details.roomTypeId = lodge.roomTypes[0].id
        }
      }
    },
    selectedProperty () {
      this.details.treehouses = null
      if (this.details.propertyId) {
        const property = this.properties.filter(p => p.id === this.details.propertyId)[0]
        this.lodges = property.lodges
        if (this.lodges && this.lodges.length === 1) {
          this.details.lodgeId = property.lodges[0].id
          this.selectedLodge(this.lodges[0])
        } else {
          this.details.lodgeId = null
          this.selectedLodge()
        }
      } else {
        this.lodges = null
        this.details.lodgeId = null
        this.selectedLodge()
      }
    }
  }
}
</script>
<style scoped>
.white_background {
  background: #fff !important;
}
</style>
